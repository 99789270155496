import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/auth_actions";
import Logo from "../assets/logo.svg";
import grid from "../assets/icons/grid.svg";
import settings from "../assets/icons/settings.svg";
import pieChart from "../assets/icons/pie-chart.svg";
import users from "../assets/icons/users.svg";
import smartphone from "../assets/icons/smartphone.svg";
import logoutIcon from "../assets/icons/logout.svg";
import { capitalize, capitalizeAll } from "../utils/functions";
const MyDrawer = () => {
  const dataUser = useSelector((state) => state.auth.userData);
  console.log("loged", dataUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);
  const location = useLocation();
  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
    console.log("location", location);
  };

  return (
    <div className="flex flex-col h-screen w-auto lg:w-64 bg-white shadow-lg">
      {/* Logo */}
      <div className="flex items-center justify-center py-4 border-b">
        <img src={Logo} alt="Logo" className="h-auto w-50" />
      </div>

      {/* User Info */}
      <div className="flex justify-center py-4">
        <div className="flex items-center px-4 py-4 border-2 gap-3 bg-gray-100 w-11/12 rounded-xl">
          <div className="w-12 h-12 rounded-full flex items-center justify-center">
            {/* Placeholder Avatar */}

            <img
              src={dataUser?.image}
              alt="User Avatar"
              className="rounded-full"
            />
          </div>
          <div>
            <p className="font-medium text-gray-700">
              {capitalizeAll(dataUser?.name)}
            </p>
            <p className="text-sm text-gray-500">{dataUser?.address}</p>
          </div>
        </div>
      </div>

      {/* Menu Items */}
      <div className="flex-1 py-4 px-4 overflow-y-auto">
        <nav className="flex flex-col gap-2">
          <NavLink
            to="/reports"
            className={({ isActive }) =>
              `flex items-center gap-3 px-3 py-4    border-orange-100 rounded-2xl text-gray-700 ${
                isActive
                  ? "border-2 text-orangeFoodiv bg-orange-50"
                  : "hover:bg-gray-100"
              }`
            }
          >
            <img src={pieChart} />
            Reportes
          </NavLink>
          <NavLink
            to="/catalogo"
            className={({ isActive }) =>
              `flex items-center gap-3 px-3 py-4 border-orange-100 rounded-2xl text-gray-700 ${
                isActive
                  ? "border-2 text-orangeFoodiv bg-orange-50"
                  : "hover:bg-gray-100"
              }`
            }
          >
            <img src={smartphone} alt="Catálogo" />
            Catálogo
          </NavLink>
          <NavLink
            to="/tables"
            className={({ isActive }) =>
              `flex items-center gap-3 px-3 py-4 border-orange-100 rounded-2xl text-gray-700 ${
                isActive
                  ? "border-2 text-orangeFoodiv bg-orange-50"
                  : "hover:bg-gray-100"
              }`
            }
          >
            <img src={grid} alt="Mesas" />
            Mesas
          </NavLink>
          {/* <NavLink
            to="/pos"
            className={({ isActive }) =>
              `flex items-center gap-3 px-3 py-5 border-orange-100 rounded-2xl text-gray-700 ${
                isActive
                  ? "border-2 text-orange-600 bg-orange-50"
                  : "hover:bg-gray-100"
              }`
            }
          >
            <img src={smartphone} alt="POS" />
            POS
          </NavLink> */}

          <NavLink
            to="/waiters"
            className={({ isActive }) =>
              `flex items-center gap-3 px-3 py-4 border-orange-100 rounded-2xl text-gray-700 ${
                isActive
                  ? "border-2 text-orangeFoodiv bg-orange-50"
                  : "hover:bg-gray-100"
              }`
            }
          >
            <img src={users} />
            Meseros
          </NavLink>

          {/* <NavLink
            to="/settings"
            className={({ isActive }) =>
              `flex items-center gap-3 px-3 py-5 border-orange-100 rounded-2xl text-gray-700 ${
                isActive
                  ? "border-2 text-orange-600 bg-orange-50"
                  : "hover:bg-gray-100"
              }`
            }
          >
            <img src={settings} />
            Configuraciones
          </NavLink> */}
        </nav>
      </div>

      {/* Footer */}
      <div className="py-4 px-4 border-t">
        <button
          className="flex items-center gap-3 px-3 py-2 w-full rounded-lg hover:bg-gray-100 text-red-700"
          onClick={handleLogout}
        >
          <img src={logoutIcon} />
          Cerrar sesión
        </button>
      </div>
    </div>
  );
};

export default MyDrawer;
