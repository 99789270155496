// export function debounce(func, wait) {
//   let timeout;
//   return function (...args) {
//     clearTimeout(timeout);
//     timeout = setTimeout(() => func(...args), wait);
//   };
// }
// // Comprimir la imagen
// export async function compressImage(file) {
//   const reader = new FileReader();
//   return new Promise((resolve, reject) => {
//     reader.onload = (event) => {
//       const img = new Image();
//       img.onload = () => {
//         const canvas = document.createElement("canvas");
//         const ctx = canvas.getContext("2d");
//         const maxSize = 800; // Reducir tamaño máximo a 800px
//         let width = img.width;
//         let height = img.height;

//         if (width > height) {
//           if (width > maxSize) {
//             height *= maxSize / width;
//             width = maxSize;
//           }
//         } else {
//           if (height > maxSize) {
//             width *= maxSize / height;
//             height = maxSize;
//           }
//         }

//         canvas.width = width;
//         canvas.height = height;
//         ctx.drawImage(img, 0, 0, width, height);
//         canvas.toBlob((blob) => {
//           resolve(blob);
//         }, file.type);
//       };
//       img.src = event.target.result;
//     };
//     reader.onerror = reject;
//     reader.readAsDataURL(file);
//   });
// }

// // Convertir Blob a Base64
// export const convertToBase64 = (blob) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = reject;
//     reader.readAsDataURL(blob);
//   });
// };

// // Generar miniatura Base64
// export const createThumbnailBase64 = async (file) => {
//   const img = document.createElement("img");
//   img.src = await convertToBase64(file);
//   return new Promise((resolve) => {
//     img.onload = () => {
//       const canvas = document.createElement("canvas");
//       const ctx = canvas.getContext("2d");

//       const maxDimension = 200; // Tamaño máximo de miniatura
//       const scale = Math.min(
//         maxDimension / img.width,
//         maxDimension / img.height
//       );
//       canvas.width = img.width * scale;
//       canvas.height = img.height * scale;

//       ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
//       resolve(canvas.toDataURL("image/jpeg", 0.7)); // Base64 comprimida al 70%
//     };
//   });
// };
export const capitalize = (text) => {
  if (text === undefined) return text;
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const capitalizeAll = (text) => {
  if (text === undefined) return text;
  return text
    .split(" ")
    .map((word) => capitalize(word))
    .join(" ");
};

export function formatToCLP(amount) {
  console.log("amount1", amount);

  if (amount !== 0) {
    const numericAmount = parseFloat(amount); // Convierte a número
    console.log("numericAmount", numericAmount); // Verifica el valor convertido

    if (isNaN(numericAmount)) {
      return 0;
    }

    // Formatear como CLP
    const formattedAmount = new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
      minimumFractionDigits: 0,
    }).format(numericAmount);

    console.log("formattedAmount", formattedAmount); // Muestra el resultado formateado
    return formattedAmount;
  } else {
    return 0;
  }
}
