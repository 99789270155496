import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as EditIcon } from "../assets/icons/edit-2.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash-1.svg";
import { ReactComponent as Plus } from "../assets/icons/plus.svg";
import { ReactComponent as Circle } from "../assets/icons/circle.svg";

import {
  getExistingTables,
  addTableToRestaurant,
  subscribeToExistingTables,
  updateTables,
} from "../database/tables";
import Swal from "sweetalert2";
import loading from "../assets/logo_animado.svg";
import { useSelector } from "react-redux";
import { capitalize, capitalizeAll } from "../utils/functions";
export default function Tables() {
  const restaurantId = useSelector((state) => state.auth.userData.id);
  const [tables, setTables] = useState([]);
  const [modalConfig, setModalConfig] = useState({
    open: false,
    mode: "create", // "create" or "edit"
    id: null,
    number: "",
  });
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState({
    open: false,
    id: null,
  });
  const [filter, setFilter] = useState("all"); // "all", "occupied", "free"
  const modalConfigRef = useRef(null);
  const modalDeleteRef = useRef(null);
  const sortTables = (tablesArray) => {
    const numeros = tablesArray.filter((item) => /^\d+$/.test(item.number));
    const alfanumericos = tablesArray.filter((item) =>
      /[A-Za-z]/.test(item.number)
    );

    numeros.sort((a, b) => Number(a.number) - Number(b.number));
    alfanumericos.sort((a, b) => a.number.localeCompare(b.number));

    return [...alfanumericos, ...numeros];
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalConfig.open &&
        modalConfigRef.current &&
        !modalConfigRef.current.contains(event.target)
      ) {
        setModalConfig({ open: false, mode: "create", id: null, number: "" });
      }
      if (
        isModalDeleteOpen.open &&
        modalDeleteRef.current &&
        !modalDeleteRef.current.contains(event.target)
      ) {
        setIsModalDeleteOpen({ open: false, id: null });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalConfig, isModalDeleteOpen]);

  useEffect(() => {
    async function fetchTables() {
      try {
        const existingTables = await getExistingTables(restaurantId);
        const sortedTables = sortTables(existingTables);
        setTables(sortedTables);
      } catch (error) {
        console.error("Error fetching tables:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudo obtener las mesas existentes.",
        });
      }
    }
    fetchTables();
  }, [restaurantId]);

  // Suscribirse a cambios en tiempo real
  useEffect(() => {
    const unsubscribe = subscribeToExistingTables(
      restaurantId,
      (updatedTables) => {
        const sortedTables = sortTables(updatedTables);
        setTables(sortedTables);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [restaurantId]);

  const handleModalSubmit = async () => {
    const { mode, id, number } = modalConfig;
    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    if (number.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Campo vacío",
        text: "Por favor, ingresa un número de mesa válido.",
      });
      return;
    }

    try {
      if (mode === "create") {
        // Crear nueva mesa
        const newTable = { number, status: "free" };
        await addTableToRestaurant(restaurantId, newTable);
        setTables(sortTables([...tables, newTable]));
      } else if (mode === "edit") {
        // Editar mesa existente
        const updatedTables = tables.map((table) =>
          table.number === id ? { ...table, number } : table
        );
        await updateTables(restaurantId, updatedTables);
        setTables(sortTables(updatedTables));
      }

      setModalConfig({ open: false, mode: "create", id: null, number: "" });

      Swal.fire({
        icon: "success",
        title: "Completado",
        text:
          mode === "create"
            ? "Mesa creada correctamente"
            : "Mesa actualizada correctamente",
        timer: 1500,
        showConfirmButton: false,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Upss...",
        text:
          mode === "create"
            ? "No se pudo crear la mesa correctamente."
            : "No se pudo actualizar la mesa.",
      });
      console.error("Error en el modal:", error);
    }
  };

  const deleteTable = async (tableNumber) => {
    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    try {
      const updatedTables = tables.filter(
        (table) => table.number !== tableNumber
      );
      await updateTables(restaurantId, updatedTables);
      setTables(updatedTables);
      setIsModalDeleteOpen({ open: false, id: null });
      Swal.fire({
        icon: "success",
        title: "Completado",
        text: "Mesa eliminada correctamente",
        timer: 1500,
        showConfirmButton: false,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Upss...",
        text: "No se pudo eliminar la mesa correctamente.",
      });
      console.error("Error al eliminar mesa:", error);
    }
  };

  const filteredTables = tables.filter((table) => {
    if (filter === "all") return true;
    return table.status === filter;
  });

  return (
    <div className="w-full">
      <div className="w-full max-w-screen-xl mx-auto">
        <div className="flex items-center justify-between px-4 py-3">
          <h1 className="text-orangeFoodiv text-3xl font-semibold">Mesas</h1>
          <button
            onClick={() =>
              setModalConfig({
                open: true,
                mode: "create",
                id: null,
                number: "",
              })
            }
            className="flex items-center text-orangeFoodiv p-3 rounded-2xl w-auto hover:bg-orange-100 border-2 border-orange-100"
            aria-label="Agregar nueva mesa"
          >
            <Plus className="mr-2" />
            Nueva mesa
          </button>
        </div>
        <div className="flex justify-start space-x-4 my-4">
          <button
            className={`px-4 py-2 rounded-xl ${
              filter === "all" ? "bg-orangeFoodiv text-white" : "bg-gray-200"
            }`}
            onClick={() => setFilter("all")}
          >
            Todas
          </button>
          <button
            className={`px-4 py-2 rounded-xl border ${
              filter === "occupied"
                ? "bg-orangeFoodiv text-white"
                : "bg-gray-200"
            }`}
            onClick={() => setFilter("occupied")}
          >
            Ocupadas
          </button>
          <button
            className={`px-4 py-2 rounded-xl border ${
              filter === "free" ? "bg-orangeFoodiv text-white" : "bg-gray-200"
            }`}
            onClick={() => setFilter("free")}
          >
            Libres
          </button>
        </div>
        {filteredTables.map((table, index) => (
          <div
            key={index}
            className="flex items-center justify-between bg-white rounded-2xl shadow-sm p-5 mb-4"
          >
            <div className="flex items-center">
              <button
                onClick={() =>
                  setModalConfig({
                    open: true,
                    mode: "edit",
                    id: table.number,
                    number: table.number,
                  })
                }
                className="shadow-md rounded-xl p-2 hover:shadow-red-200 transition mr-4"
              >
                <EditIcon className="text-gray-500 w-6 h-6" />
              </button>
              <p className="text-gray-700 text-base w-24">
                Mesa {table.number}
              </p>
              <div className="flex items-center ml-24">
                <Circle
                  className={`w-6 h-6 p-1 ${
                    table.status === "occupied"
                      ? "text-red-500"
                      : "text-green-500"
                  }`}
                />
                <p className="text-gray-700 text-base ml-2">
                  {table.status === "occupied" ? "Mesa ocupada" : "Mesa libre"}
                </p>
              </div>
            </div>
            <button
              onClick={() => {
                if (table.status === "occupied") {
                  Swal.fire({
                    icon: "info",
                    title: "No es posible borrar esta mesa",
                    text: "Debe limpiar la mesa desde la app para poder eliminarla ",
                  });
                } else {
                  setIsModalDeleteOpen({ open: true, id: table.number });
                }
              }}
              className="shadow-md rounded-xl p-2 hover:shadow-red-200 transition"
            >
              <TrashIcon className="text-red-500 w-6 h-6" />
            </button>
          </div>
        ))}
      </div>

      {modalConfig.open && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div
            ref={modalConfigRef}
            className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm flex flex-col text-center"
          >
            <h2 className="text-2xl mb-4 text-orangeFoodiv">
              {modalConfig.mode === "create" ? "Nueva Mesa" : "Editar Mesa"}
            </h2>
            <h5 className="text-md mb-4">Número de mesa</h5>
            <input
              placeholder="Ingresar número de la mesa"
              value={capitalize(modalConfig.number)}
              onChange={(e) =>
                setModalConfig({
                  ...modalConfig,
                  number: capitalize(e.target.value),
                })
              }
              className="w-full p-4 bg-gray-100 rounded-2xl mb-4 focus:outline-none focus:ring-0"
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleModalSubmit();
                }
              }}
            />
            <div className="flex justify-end">
              <button
                onClick={() =>
                  setModalConfig({
                    open: false,
                    mode: "create",
                    id: null,
                    number: "",
                  })
                }
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded-2xl hover:bg-gray-300 mr-2"
              >
                Cancelar
              </button>
              <button
                onClick={handleModalSubmit}
                className="bg-orangeFoodiv text-white px-4 py-2 rounded-2xl hover:bg-orange-500"
              >
                {modalConfig.mode === "create" ? "Crear" : "Guardar"}
              </button>
            </div>
          </div>
        </div>
      )}

      {isModalDeleteOpen.open && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div
            ref={modalDeleteRef}
            className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm flex flex-col items-center"
          >
            <TrashIcon className="text-orangeFoodiv w-28 h-28 mb-3" />

            <h2 className="text-lg font-semibold mb-4">
              ¿Estás seguro de eliminar esta mesa?
            </h2>
            <div className="flex justify-end items-end">
              <button
                onClick={() => setIsModalDeleteOpen({ open: false, id: null })}
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 mr-2"
              >
                Cancelar
              </button>
              <button
                onClick={() => deleteTable(isModalDeleteOpen.id)}
                className="bg-orangeFoodiv text-white px-4 py-2 rounded hover:bg-red-300"
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
