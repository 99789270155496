import React, { useState, useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import MyDrawer from "../components/drawer";
import { db } from "../database/config";
import { doc, onSnapshot } from "firebase/firestore";
import { useSelector } from "react-redux";

const drawerWidth = 280; // Cambia este valor según sea necesario
const collapsedDrawerWidth = 30; // Ancho cuando el drawer está contraído
const appBarHeight = 45;

const Layout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true); // Estado para controlar el drawer en pantallas grandes

  const location = useLocation();
  const userId = useSelector((state) => state.auth.idUser);
  const [availableSheets, setAvailableSheets] = useState([]);

  useEffect(() => {
    if (!userId) return;

    const userDocRef = doc(db, "workspaces", userId);
    const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        setAvailableSheets(data.hojas || []);
      } else {
        setAvailableSheets([]);
      }
    });

    return () => unsubscribe();
  }, [userId]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Controlar el estado del drawer en pantallas grandes
  const handleDrawerToggleDesktop = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div
      className="flex w-full h-full overflow-hidden"
      style={{ backgroundColor: "#f7f7f8" }}
    >
      <MyDrawer
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
        drawerOpen={drawerOpen}
        handleDrawerToggleDesktop={handleDrawerToggleDesktop}
        availableSheets={availableSheets} // Pasar availableSheets como prop a MyDrawer
      />
      <main
        className={`flex-grow p-0 md:py-2 md:pl-2 lg:pl-4 md:pr-2 lg:pr-7 mt-[25px] overflow-auto ${
          drawerOpen
            ? `lg:ml-[${drawerWidth}px]`
            : `lg:ml-[${collapsedDrawerWidth}px]`
        }`}
        style={{
          width: `calc(100vw - ${
            drawerOpen ? drawerWidth : collapsedDrawerWidth
          }px)`,
          height: `calc(100vh - ${appBarHeight}px)`,
        }}
      >
        <Outlet context={{ availableSheets }} />{" "}
        {/* Pasar availableSheets como contexto al Outlet */}
      </main>
    </div>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Layout;
