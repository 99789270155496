import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as EditIcon } from "../assets/icons/edit-2.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash-1.svg";
import { ReactComponent as Plus } from "../assets/icons/plus.svg";
import Swal from "sweetalert2";
import loading from "../assets/logo_animado.svg";
import {
  addWaiterToRestaurant,
  getExistingWaiters,
  updateWaiters,
} from "../database/waiters";
import { capitalizeAll } from "../utils/functions";
import { useSelector } from "react-redux";

export default function Waiters() {
  const restaurantId = useSelector((state) => state.auth.userData.id);
  const modalRef = useRef(null);
  const deleteModalRef = useRef(null);
  const [waiters, setWaiters] = useState([]);
  const [modalState, setModalState] = useState({
    open: false,
    mode: "create",
    username: "",
    pin: "",
    status: "active", // Valor predeterminado
  });
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState({
    open: false,
    username: null,
  });

  const sortWaiters = (waitersArray) => {
    return waitersArray.sort((a, b) => a.username.localeCompare(b.username));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalState.open &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        setModalState({
          open: false,
          mode: "create",
          username: "",
          pin: "",
          status: "active",
        });
      }

      if (
        isModalDeleteOpen.open &&
        deleteModalRef.current &&
        !deleteModalRef.current.contains(event.target)
      ) {
        setIsModalDeleteOpen({ open: false, username: null });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalState, isModalDeleteOpen]);

  useEffect(() => {
    async function fetchWaiters() {
      try {
        const existingWaiters = await getExistingWaiters(restaurantId);
        setWaiters(sortWaiters(existingWaiters));
      } catch (error) {
        console.error("Error fetching waiters:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No se pudo obtener los meseros existentes.",
        });
      }
    }
    fetchWaiters();
  }, [restaurantId]);

  const handleSave = async () => {
    const { mode, username, pin, status } = modalState;

    if (username.trim() === "" || pin.trim() === "") {
      Swal.fire({
        icon: "warning",
        title: "Campos vacíos",
        text: "Por favor, revisa los datos ingresados.",
      });
      return;
    }

    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    try {
      if (mode === "create") {
        const newWaiter = {
          username: username,
          pin: pin,
          status: status || "active", // Asigna un valor por defecto si status no está definido
        };
        await addWaiterToRestaurant(restaurantId, newWaiter);
        const updatedWaiters = sortWaiters([...waiters, newWaiter]);
        setWaiters(updatedWaiters);
      } else if (mode === "edit") {
        const updatedWaiters = waiters.map((waiter) => {
          if (waiter.pin === pin) {
            return {
              ...waiter,
              username: username,
              status: status || waiter.status, // Mantén el estado previo si no se define uno nuevo
            };
          }
          return waiter;
        });

        await updateWaiters(restaurantId, updatedWaiters);
        setWaiters(sortWaiters(updatedWaiters));
      }

      setModalState({
        open: false,
        mode: "create",
        username: "",
        pin: "",
        status: "active",
      });
      Swal.close();
    } catch (error) {
      console.error("Error al actualizar meseros:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Hubo un problema al realizar la operación.",
      });
    } finally {
      Swal.close();
    }
  };

  const deleteWaiter = async (username) => {
    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    try {
      const updatedWaiters = waiters.filter(
        (waiter) => waiter.username !== username
      );
      await updateWaiters(restaurantId, updatedWaiters);
      setWaiters(updatedWaiters);
      setIsModalDeleteOpen({ open: false, username: null });

      Swal.fire({
        icon: "success",
        title: "Completado",
        text: "Mesero eliminado correctamente.",
        timer: 1500,
        showConfirmButton: false,
      });

      setModalState({
        open: false,
        mode: "create",
        username: "",
        pin: "",
        status: "active",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Upss...",
        text: "No se ha podido eliminar el mesero.",
      });
      console.error("Error al eliminar mesero:", error);
    } finally {
      Swal.close();
    }
  };

  return (
    <div className="w-full">
      <div className="w-full max-w-screen-xl mx-auto">
        <div className="flex items-center justify-between px-4 py-3 mb-7">
          <h1 className="text-orangeFoodiv text-3xl font-semibold">Meseros</h1>
          <button
            onClick={() =>
              setModalState({
                open: true,
                mode: "create",
                status: "active",
                username: "",
                pin: Math.floor(1000 + Math.random() * 9000).toString(),
              })
            }
            className="flex items-center text-orangeFoodiv p-3 rounded-2xl w-auto hover:bg-orange-100 border-2 border-orange-100"
          >
            <Plus className="mr-2" />
            Nuevo mesero
          </button>
        </div>
        {waiters.map((waiter, index) => (
          <div
            key={index}
            className="flex items-center justify-between bg-white rounded-2xl shadow-sm p-5 mb-4"
          >
            <div className="flex items-center">
              <button
                onClick={() =>
                  setModalState({
                    open: true,
                    mode: "edit",
                    username: waiter.username,
                    pin: waiter.pin,
                    status: waiter.status, // Aseguramos que el estado actual se pase al modal
                  })
                }
                className="shadow-md rounded-xl p-2 hover:shadow-red-200 transition mr-4"
              >
                <EditIcon className="text-gray-500 w-6 h-6" />
              </button>
              <div>
                <p className="text-gray-700 text-base font-bold w-auto">
                  {capitalizeAll(waiter.username)}
                </p>
                {waiter.status === "active" ? (
                  <p className="text-green-500 text-base w-24">Activo</p>
                ) : (
                  <p className="text-red-500 text-base w-24">Inactivo</p>
                )}
              </div>
            </div>

            <button
              onClick={() =>
                setIsModalDeleteOpen({ open: true, username: waiter.username })
              }
              className="shadow-md rounded-xl p-2 hover:shadow-red-200 transition"
            >
              <TrashIcon className="text-red-500 w-6 h-6" />
            </button>
          </div>
        ))}
      </div>
      {modalState.open && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div
            ref={modalRef}
            className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm flex flex-col text-center"
          >
            <h2 className="text-2xl mb-4 text-orangeFoodiv">
              {modalState.mode === "create" ? "Nuevo mesero" : "Editar mesero"}
            </h2>
            <input
              placeholder="Nombre del mesero"
              value={capitalizeAll(modalState.username)}
              onChange={(e) =>
                setModalState({
                  ...modalState,
                  username: capitalizeAll(e.target.value),
                })
              }
              className="w-full p-4 bg-gray-100 rounded-2xl mb-4 focus:outline-none focus:ring-0"
            />
            <input
              placeholder="PIN"
              value={modalState.pin}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value) && value.length <= 4) {
                  setModalState({ ...modalState, pin: value });
                }
              }}
              className="w-full p-4 bg-gray-100 rounded-2xl mb-4 focus:outline-none focus:ring-0"
            />
            <select
              value={modalState.status || "active"} // Estado por defecto
              onChange={(e) =>
                setModalState({ ...modalState, status: e.target.value })
              }
              className="w-full p-4 bg-gray-100 rounded-2xl mb-4 focus:outline-none focus:ring-0"
            >
              <option value="active">Activo</option>
              <option value="inactive">Inactivo</option>
            </select>
            <div className="flex justify-end">
              <button
                onClick={() =>
                  setModalState({
                    open: false,
                    mode: "create",
                    username: "",
                    pin: "",
                    status: "active",
                  })
                }
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded-2xl hover:bg-gray-300 mr-2"
              >
                Cancelar
              </button>
              <button
                onClick={handleSave}
                className="bg-orangeFoodiv text-white px-4 py-2 rounded-2xl hover:bg-orange-500"
              >
                {modalState.mode === "create" ? "Crear" : "Guardar"}
              </button>
            </div>
          </div>
        </div>
      )}

      {isModalDeleteOpen.open && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div
            ref={deleteModalRef}
            className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm"
          >
            <h2 className="text-lg font-semibold mb-4">
              ¿Estás seguro de eliminar este mesero?
            </h2>
            <div className="flex justify-end">
              <button
                onClick={() =>
                  setIsModalDeleteOpen({ open: false, username: null })
                }
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 mr-2"
              >
                Cancelar
              </button>
              <button
                onClick={() => deleteWaiter(isModalDeleteOpen.username)}
                className="bg-orangeFoodiv text-white px-4 py-2 rounded hover:bg-orange-500"
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
