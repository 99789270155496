import React, { useState } from "react";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("cuenta");

  const cuenta = () => (
    <div className="w-full h-full p-6 rounded-lg flex flex-col justify-between">
      <div>
        <h2 className="text-lg font-semibold text-orange-500 mb-6">
          Configuraciones &gt; Cuenta
        </h2>
        <form className="space-y-4 w-full">
          {/* Username Field */}
          <div className="w-full">
            <label className="block text-gray-700 font-medium mb-2 flex items-center">
              <i className="material-icons mr-2 text-gray-600">person</i>
              Nombre de usuario
            </label>
            <input
              type="text"
              className="w-full px-4 py-3 border border-gray-200 rounded-md bg-gray-100 text-gray-700"
              defaultValue="Javier Órdenes"
            />
          </div>

          {/* Email Field */}
          <div className="w-full">
            <label className="block text-gray-700 font-medium mb-2 flex items-center">
              <i className="material-icons mr-2 text-gray-600">email</i>
              Email
            </label>
            <input
              type="email"
              className="w-full px-4 py-3 border border-gray-200 rounded-md bg-gray-100 text-gray-700"
              defaultValue="javierons@gmail.com"
            />
          </div>
        </form>
      </div>
      <div className="flex justify-end mt-8 border-t border-gray-200 w-full pt-4 px-6">
        <button
          type="button"
          className="px-6 py-3 text-white bg-orange-500 rounded-full hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500"
        >
          Guardar cambios
        </button>
      </div>
    </div>
  );

  const contrasena = () => (
    <div className="w-full h-full p-6  rounded-lg flex flex-col justify-between">
      <div>
        <h2 className="text-lg font-semibold text-orange-500 mb-6">
          Configuraciones &gt; Contraseña
        </h2>
        <form className="space-y-4 flex-grow">
          {/* Current Password Field */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Contraseña actual
            </label>
            <input
              type="password"
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>

          {/* New Password Field */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Nueva contraseña
            </label>
            <input
              type="password"
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>

          {/* Confirm New Password Field */}
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Confirmar nueva contraseña
            </label>
            <input
              type="password"
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
            />
          </div>
        </form>
      </div>
      <div className="flex justify-end mt-8 border-t border-gray-200 w-full pt-4 -mx-6 px-6">
        <button
          type="button"
          className="px-6 py-3 text-white bg-orange-500 rounded-full hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500"
        >
          Guardar cambios
        </button>
      </div>
    </div>
  );

  const renderContent = () => {
    switch (activeTab) {
      case "cuenta":
        return cuenta();
      case "contrasena":
        return contrasena();
      default:
        return cuenta();
    }
  };

  return (
    <div className="min-h-full flex flex-col md:flex-row bg-white rounded-2xl">
      <aside className="w-full md:w-1/4 lg:w-1/5 border-r p-1 bg-white">
        <nav className="space-y-4">
          <button
            onClick={() => setActiveTab("cuenta")}
            className={`w-full text-left p-4 rounded-md ${
              activeTab === "cuenta"
                ? "text-orange-500 font-semibold bg-gray-100"
                : "text-gray-700 hover:text-orange-500"
            }`}
          >
            Cuenta
          </button>
          <button
            onClick={() => setActiveTab("contrasena")}
            className={`w-full text-left p-4 rounded-md ${
              activeTab === "contrasena"
                ? "text-orange-500 font-semibold bg-gray-100"
                : "text-gray-700 hover:text-orange-500"
            }`}
          >
            Contraseña
          </button>
        </nav>
      </aside>

      {/* Main Content */}
      <main className="flex-grow p-0">{renderContent()}</main>
    </div>
  );
};

export default Settings;
