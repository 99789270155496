import { arrayUnion, doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../database/config";

export const addWaiterToRestaurant = async (restaurantId, newWaiter) => {
  console.log("restaurantId", restaurantId);
  console.log("newWaiter", newWaiter);

  try {
    const restaurantDocRef = doc(db, "restaurants", restaurantId);

    await updateDoc(restaurantDocRef, {
      users: arrayUnion(newWaiter),
    });

    console.log("Mesero añadido correctamente");
  } catch (error) {
    console.error("Error al añadir nuevo mesero: ", error);
  }
};

export const getExistingWaiters = async (restaurantId) => {
  try {
    const restaurantDocRef = doc(db, "restaurants", restaurantId);

    const docSnapshot = await getDoc(restaurantDocRef);

    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      return data.users || [];
    } else {
      console.log("El documento no existe.");
      return [];
    }
  } catch (error) {
    console.error("Error al obtener a los meseros existentes: ", error);
    return [];
  }
};

export const updateWaiters = async (restaurantId, updatedWaiter) => {
  try {
    const restaurantDocRef = doc(db, "restaurants", restaurantId);

    await updateDoc(restaurantDocRef, {
      users: updatedWaiter,
    });

    console.log("Mesas actualizadas correctamente.");
  } catch (error) {
    console.error("Error al actualizar las mesas: ", error);
  }
};
