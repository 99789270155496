// authReducer.js
const initialState = {
  logged: false,
  userData: null,
  idUser: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        logged: true,
        userData: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        logged: false,
        userData: null,
      };
    case "UPDATE_USER_DATA":
      return {
        ...state,
        userData: action.payload,
        logged: true,
      };
    default:
      return state;
  }
};

export default authReducer;
