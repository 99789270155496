import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from "chart.js";
import "tailwindcss/tailwind.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

const ChartComponent = ({ dataPoints }) => {
  // Generar las etiquetas de los meses dinámicamente, asegurando que el último sea el mes actual
  const currentMonthIndex = new Date().getMonth();
  const monthLabels = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];
  const labels = [];

  for (let i = 4; i >= 0; i--) {
    const monthIndex = (currentMonthIndex - i + 12) % 12;
    labels.push(monthLabels[monthIndex]);
  }

  // Datos para el grafico
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Total mes",
        data: dataPoints,
        borderColor: "rgba(234, 88, 12, 1)",
        backgroundColor: "rgba(234, 88, 12, 0.2)",
        fill: true,
        tension: 0.4,
        pointBackgroundColor: "rgba(234, 88, 12, 1)",
      },
    ],
  };

  // Configuracion para hacer el grafico responsivo
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        titleColor: "#fff",
        bodyColor: "#fff",
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: "white",
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="w-full h-full">
      <div className="bg-[#fee8e1] p-6 rounded-2xl shadow-sm h-80">
        <div className="flex justify-between items-center">
          <span className="text-lg font-semibold">Total mes</span>
          <span className="bg-black text-white px-4 py-2 rounded-lg">
            ${dataPoints.reduce((a, b) => a + b, 0).toFixed(2)}
          </span>
        </div>
        <div className="h-64">
          <Line data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default ChartComponent;
