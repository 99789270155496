import React, { useState } from "react";
// import { LockClosedIcon, HashtagIcon } from "@heroicons/react/outline";
import logo from "../assets/logo.svg";
import { handleLoginUser } from "../database/login";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import Hashtag from "../components/icons/Hashtag";

import { ReactComponent as Hashtag } from "../assets/icons/hashtag.svg";
import { ReactComponent as Locked } from "../assets/icons/locked.svg";

import Swal from "sweetalert2";
import loading from "../assets/logo_animado.svg";
import { login } from "../actions/auth_actions";
function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [pass, setPass] = useState("");

  const handleUpdateLogin = (userData) => {
    dispatch(login(userData));
    console.log("dataa", userData);
  };

  async function handleLogin() {
    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    try {
      let response = await handleLoginUser(code, pass);
      console.log("Login Response:", response);
      if (!response) {
        Swal.fire({
          icon: "warning",
          title: "Upss..",
          text: "Compruebe sus credenciales de inicio de sesión",
        });
      } else {
        handleUpdateLogin(response);
        navigate("/reports");
        Swal.close();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Upss..",
        text: "ha ocurrido un error al iniciar sesión",
      });
      console.log("Error login", error);
    }

    // Puedes agregar lógica adicional, como redirecciones o mensajes de error, aquí.
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-[500px] w-full bg-white rounded-3xl shadow-md p-6 border-1 border-gray-300 grid gap-6">
        <div className="text-center">
          <img
            src={logo}
            alt="Logo"
            className="mx-auto mb-5 w-full max-w-[400px]"
          />
        </div>
        <div className="grid gap-6">
          {/* Input Código de Restaurante */}
          <div className="relative grid gap-2">
            <label
              htmlFor="restaurantCode"
              className="block text-sm font-medium text-gray-700"
            >
              Código de restaurante
            </label>
            <div className="relative">
              <Hashtag className="w-[20px] h-[20px] text-gray-700 absolute left-3 top-1/2 transform -translate-y-1/2 " />
           
              <input
                id="restaurantCode"
                type="text"
                placeholder="Código de restaurante"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="appearance-none pl-10 w-full px-4 py-2 border border-gray-300 rounded-md bg-slate-300 focus:ring-orange-500 focus:border-orange-500"
              />
            </div>
          </div>

          {/* Input PIN de Restaurante */}
          <div className="relative grid gap-2">
            <label
              htmlFor="restaurantPin"
              className="block text-sm font-medium text-gray-700"
            >
              PIN de restaurante
            </label>
            <div className="relative">
              <Locked className="w-[20px] h-[20px] text-gray-700 absolute left-3 top-1/2 transform -translate-y-1/2 " />

              <input
                type="password"
                placeholder="PIN de restaurante"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                className="appearance-none pl-10 w-full px-4 py-2 border border-gray-300 rounded-md bg-slate-300 focus:ring-orange-500 focus:border-orange-500"
                onKeyUp={(event) => {
                  console.log("Key pressed:", event.key);
                  if (event.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
            </div>
          </div>

          {/* Botón Ingresar */}
          <button
            onClick={handleLogin} // Aquí manejamos el evento al hacer clic
            className="w-full py-2 px-4 bg-orange-600 text-white rounded-md hover:bg-orange-700 transition"
          >
            Ingresar
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
