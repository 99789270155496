import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Lens } from "../assets/icons/lens.svg";
import { ReactComponent as Plus } from "../assets/icons/plus.svg";
import { ReactComponent as BookOpen } from "../assets/icons/book-open.svg";
import { ReactComponent as Box } from "../assets/icons/box.svg";
import { ReactComponent as AlignLeft } from "../assets/icons/align-left.svg";
import { ReactComponent as DollarSign } from "../assets/icons/dollar-sign.svg";
import { ReactComponent as LabelFull } from "../assets/icons/label-full.svg";
import { ReactComponent as Package } from "../assets/icons/package.svg";
import { ReactComponent as ChevronRight } from "../assets/icons/chevron-right.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash-1.svg";
import loading from "../assets/logo_animado.svg";
import {
  getCatalogsByRestaurantId,
  updateCatalog,
  deleteProduct,
  createNewCatalog,
} from "../database/catalog";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { capitalize, capitalizeAll, formatToCLP } from "../utils/functions";

const Catalogo = () => {
  const restaurantId = useSelector((state) => state.auth.userData.id);
  const modalCategoriRef = useRef(null);
  const modalProductsRef = useRef(null);
  const [catalogoData, setCatalogoData] = useState([]);
  const [modalCategori, setModalCategori] = useState({
    open: false,
    mode: "create",
    categoryName: "",
  });
  const [modalProducts, setModalProducts] = useState({
    open: false,
    mode: "create",
    categoryName: "",
    producName: "",
    price: "",
    description: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTab, setSelectedTab] = useState("todas");
  const [categories, setCategories] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [isDrink, setIsDrink] = useState(false);

  const handleDrinkChange = () => {
    setIsDrink(true); // Selecciona que es bebestible
  };

  const handleEdibleChange = () => {
    setIsDrink(false); // Selecciona que es comestible
  };
  useEffect(() => {
    if (selectedTab === "todas") {
      setAllProducts(
        categories.reduce((acc, category) => {
          return acc.concat(category.products);
        }, [])
      );
    } else {
      const category = categories.find((cat) => cat.name === selectedTab);
      setAllProducts(category ? category.products : []);
    }
  }, [selectedTab, categories]);

  useEffect(() => {
    const getCatalogs = async () => {
      Swal.fire({
        imageUrl: loading,
        imageWidth: 208,
        imageHeight: 209,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      const catalogs = await getCatalogsByRestaurantId(restaurantId);
      if (catalogs.length > 0) {
        const catalogData = catalogs[0];
        setCatalogoData(catalogData);
        setCategories(catalogData.categories || []);
        setAllProducts(
          catalogData.categories.reduce((acc, category) => {
            return acc.concat(category.products);
          }, [])
        );
        Swal.close();
      } else {
        setCatalogoData(null); // No existe catálogo
        setCategories([]);
        Swal.close();
      }
    };
    getCatalogs();
  }, [restaurantId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalCategori.open &&
        modalCategoriRef.current &&
        !modalCategoriRef.current.contains(event.target)
      ) {
        setModalCategori({ open: false, mode: "create", categoryName: "" });
      }
      if (
        modalProducts.open &&
        modalProductsRef.current &&
        !modalProductsRef.current.contains(event.target)
      ) {
        setModalProducts({
          open: false,
          mode: "create",
          categoryName: "",
          producName: "",
          price: "",
          description: "",
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalCategori, modalProducts]);

  const scrollContainerRef = useRef(null);
  let isDragging = false;
  let startX;
  let scrollLeft;

  const handleMouseDown = (e) => {
    isDragging = true;
    startX = e.pageX - scrollContainerRef.current.offsetLeft;
    scrollLeft = scrollContainerRef.current.scrollLeft;
    scrollContainerRef.current.style.cursor = "grabbing";
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Ajusta la velocidad de desplazamiento
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUpOrLeave = () => {
    isDragging = false;
    scrollContainerRef.current.style.cursor = "grab";
  };

  const handleModalSubmit = async () => {
    if (
      !modalCategori.categoryName ||
      modalCategori.categoryName.trim() === ""
    ) {
      Swal.fire({
        icon: "warning",
        title: "Campo vacío",
        text: "Por favor, ingresa un nombre para la categoría.",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    const isDuplicate = categories.some(
      (category) =>
        category.name.toLowerCase() === modalCategori.categoryName.toLowerCase()
    );
    if (isDuplicate) {
      Swal.fire({
        icon: "warning",
        title: "Nombre duplicado",
        text: "Ya existe una categoría con este nombre. Intenta con otro.",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    if (modalCategori.mode === "create" && modalCategori.categoryName) {
      const newCategory = {
        name: modalCategori.categoryName,
        products: [],
      };

      const updatedCategories = [...categories, newCategory];
      setCategories(updatedCategories);

      try {
        if (!catalogoData || !catalogoData.id) {
          const catalogId = await createNewCatalog(
            restaurantId,
            updatedCategories
          );
          setCatalogoData({ id: catalogId, categories: updatedCategories });
        } else {
          await updateCatalog(catalogoData.id, {
            categories: updatedCategories,
          });
          setCatalogoData((prev) => ({
            ...prev,
            categories: updatedCategories,
          }));
        }

        setSelectedTab(modalCategori.categoryName);
        setModalProducts((prevState) => ({
          ...prevState,
          categoryName: modalCategori.categoryName,
        }));

        Swal.fire({
          icon: "success",
          title: "Categoría creada",
          text: "La categoría se creó correctamente.",
          confirmButtonText: "Aceptar",
          timer: 1400,
        });
      } catch (error) {
        console.error("Error al manejar el modal de categoría:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error al crear la categoría. Inténtalo de nuevo.",
          confirmButtonText: "Aceptar",
          timer: 1400,
        });
      }
    } else if (modalCategori.mode === "edit" && modalCategori.categoryName) {
      const updatedCategories = categories.map((category) =>
        capitalize(category.name) === modalCategori.categoryName
          ? { ...category, name: modalCategori.categoryName }
          : category
      );
      setCategories(updatedCategories);

      if (catalogoData && catalogoData.id) {
        try {
          await updateCatalog(catalogoData.id, {
            categories: updatedCategories,
          });
          Swal.fire({
            icon: "success",
            title: "Categoría actualizada",
            text: "La categoría se actualizó correctamente.",
            confirmButtonText: "Aceptar",
            timer: 1400,
          });
        } catch (error) {
          console.error("Error al actualizar la categoría:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Ocurrió un error al actualizar la categoría. Inténtalo de nuevo.",
            confirmButtonText: "Aceptar",
            timer: 1400,
          });
        }
      }
    }

    setModalCategori({ open: false, mode: "create", categoryName: "" });
  };

  const handleDeleteCategory = async () => {
    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    if (!selectedTab || selectedTab === "todas") {
      Swal.fire({
        icon: "warning",
        title: "Categoría no seleccionada",
        text: "Por favor selecciona una categoría para eliminar.",
        confirmButtonText: "Aceptar",
        timer: 1400,
      });
      return;
    }
    const updatedCategories = categories.filter(
      (category) => category.name !== selectedTab
    );
    if (updatedCategories.length === categories.length) {
      Swal.fire({
        icon: "warning",
        title: "Categoría inexistente",
        text: "La categoría no existe.",
        confirmButtonText: "Aceptar",
        timer: 1400,
      });
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "¿Estas seguro que deseas eliminar esta categoría?",
      text: "al eliminar la categoría se borraran todos los productos asociados a esta misma.",
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: "cancelar",
      confirmButtonText: "Si, elimnar",
    }).then(async (e) => {
      if (e.isConfirmed) {
        Swal.fire({
          imageUrl: loading,
          imageWidth: 208,
          imageHeight: 209,
          title: "Cargando...",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        try {
          await updateCatalog(catalogoData.id, {
            categories: updatedCategories,
          });
          setCategories(updatedCategories);
          if (selectedTab === selectedTab) {
            setSelectedTab("todas");
          }
          Swal.fire({
            icon: "success",
            title: "Categoría Eliminada",
            text: "Categoría eliminada correctamente.",
            confirmButtonText: "Aceptar",
            timer: 1400,
          });
        } catch (error) {
          console.error("Error al eliminar la categoría:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Error al eliminar la categoría. Inténtalo nuevamente.",
            confirmButtonText: "Aceptar",
            timer: 1400,
          });
        }
      }
    });
  };

  const handleDeleteProdut = async () => {
    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    if (!modalProducts.producName) {
      Swal.fire({
        icon: "warning",
        title: "Producto no seleccionado",
        text: "Por favor selecciona un producto para eliminar.",
        confirmButtonText: "Aceptar",
        timer: 1400,
      });
      return;
    }

    Swal.fire({
      icon: "warning",
      title: "Estas por borrar un producto.",
      text: "¿seguro que quieres eliminar este producto?",
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, eliminar",
    }).then(async (e) => {
      if (e.isConfirmed) {
        try {
          await deleteProduct(modalProducts.producName, restaurantId);
          const updatedCategories = categories.map((category) => {
            return {
              ...category,
              products: category.products.filter(
                (product) => product.name !== modalProducts.producName
              ),
            };
          });
          setCategories(updatedCategories);

          Swal.fire({
            icon: "success",
            title: "Producto Eliminado",
            text: "Producto eliminado correctamente.",
            confirmButtonText: "Aceptar",
            timer: 1400,
          });

          setModalProducts({
            open: false,
            mode: "create",
            categoryName: "",
            producName: "",
            price: "",
            description: "",
          });
        } catch (error) {
          console.error("Error al eliminar el producto:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Error al eliminar el producto. Inténtalo nuevamente.",
            confirmButtonText: "Aceptar",
            timer: 1400,
          });
        }
      }
    });
  };

  const handleEditProduct = (categoryName, product) => {
    console.log("product", product);
    setModalProducts({
      open: true,
      mode: "edit",
      categoryName: categoryName,
      producName: product.name,
      price: product.price,
      description: product.description,
    });
    setIsDrink(product.isDrink || false);
  };

  const handleModalProductSubmit = async () => {
    if (
      !modalProducts.categoryName ||
      modalProducts.categoryName.trim() === "" ||
      !modalProducts.producName ||
      modalProducts.producName.trim() === "" ||
      !modalProducts.price ||
      modalProducts.price.trim() === ""
    ) {
      Swal.fire({
        icon: "warning",
        title: "Campos vacíos",
        text: "Por favor, completa todos los campos obligatorios.",
        confirmButtonText: "Aceptar",
      });
    }
    Swal.fire({
      imageUrl: loading,
      imageWidth: 208,
      imageHeight: 209,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    let updatedCategories;
    if (modalProducts.mode === "create") {
      updatedCategories = categories.map((category) => {
        if (category.name === modalProducts.categoryName) {
          return {
            ...category,
            products: [
              ...category.products,
              {
                name: modalProducts.producName,
                price: modalProducts.price,
                description: modalProducts.description,
                isDrink: isDrink,
              },
            ],
          };
        }
        return category;
      });
    } else if (modalProducts.mode === "edit") {
      updatedCategories = categories.map((category) => {
        if (category.name === modalProducts.categoryName) {
          const updatedProducts = category.products.map((product) => {
            if (product.name === modalProducts.producName) {
              return {
                ...product,
                name: modalProducts.producName,
                price: modalProducts.price,
                description: modalProducts.description,
                isDrink: isDrink,
              };
            }
            return product;
          });
          return { ...category, products: updatedProducts };
        }
        return category;
      });
    }

    try {
      await updateCatalog(catalogoData.id, { categories: updatedCategories });
      setCategories(updatedCategories);
      Swal.fire({
        icon: "success",
        title: "Producto actualizado",
        text: "El producto se guardó correctamente.",
        confirmButtonText: "Aceptar",
        timer: 1400,
      });
    } catch (error) {
      console.error("Error updating product in catalog:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo actualizar el producto. Inténtalo nuevamente.",
        confirmButtonText: "Aceptar",
        timer: 1400,
      });
    }
    setIsDrink(false);
    setModalProducts({
      open: false,
      mode: "create",
      categoryName: "",
      producName: "",
      price: "",
      description: "",
    });
  };

  const whithContent = (
    <>
      <div className="relative grid gap-2 my-10 px-4">
        <div className="relative">
          <Lens className="w-[20px] h-[20px] text-gray-700 absolute left-3 top-1/2 transform -translate-y-1/2 " />

          <input
            id="restaurantCode"
            type="text"
            placeholder="Buscar"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="appearance-none pl-10 w-full px-4 py-2 border border-gray-300 rounded-md bg-gray-200 focus:ring-orange-500 focus:border-orange-500 focus:outline-none focus:ring-0"
          />
        </div>
        <div className="relative"></div>
      </div>
      <div className="w-full flex felx-row justify-between mb-8 items-center">
        <p className="text-gray-800 text-lg w-auto">Categorías:</p>
        <div className="w-auto flex flex-row ">
          <button
            className="flex items-center text-white p-3 rounded-2xl w-auto hover:text-gray-300  bg-orangeFoodiv m-2"
            onClick={() => {
              setModalCategori({
                open: true,
                mode: "create",
                categoryName: "",
              });
            }}
          >
            <Plus />
            Crear categoría
          </button>
          <button
            className="flex items-center text-orangeFoodiv p-3 rounded-2xl w-auto hover:bg-orange-100 border-2 border-orange-400 m-2"
            onClick={() => {
              handleDeleteCategory();
            }}
          >
            <TrashIcon />
            Eliminar categoría
          </button>
        </div>
      </div>

      <div
        ref={scrollContainerRef}
        className="flex justify-start mb-10 px-4 overflow-x-auto"
        style={{ scrollbarWidth: "none", cursor: "grab" }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUpOrLeave}
        onMouseLeave={handleMouseUpOrLeave}
      >
        {["todas", ...categories.map((category) => category.name)].map(
          (tab) => (
            <button
              key={tab}
              onClick={() => setSelectedTab(tab)}
              className={`px-4 py-2 mx-2 rounded-2xl ${
                selectedTab === tab
                  ? "bg-orangeFoodiv text-white"
                  : "bg-gray-200"
              }`}
            >
              {capitalizeAll(tab)}
            </button>
          )
        )}
      </div>

      {selectedTab === "todas" && (
        <div className="w-auto h-auto">
          {allProducts
            .filter((product) =>
              product.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((product, i) => (
              <div
                className="w-full h-[80px] bg-white border rounded-3xl p-5 flex flex-row items-center justify-between mb-4 cursor-pointer shadow-sm hover:shadow-gray-400"
                key={i}
                onClick={() => handleEditProduct(product.categoryName, product)}
              >
                <div className="flex-1">
                  <p className="text-gray-950 text-lg">
                    {capitalize(product.name)}
                  </p>
                  <p className="text-gray-600 text-md font-extralight">
                    {capitalize(product.description) || "Sin descripción"}
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <p className="text-gray-800 text-md font-medium">
                    {formatToCLP(product.price)}
                  </p>
                  <ChevronRight className="text-gray-800" />
                </div>
              </div>
            ))}

          <div className="fixed bottom-10 right-10">
            <button
              onClick={() =>
                setModalProducts({
                  open: true,
                  mode: "create",
                  categoryName: selectedTab,
                })
              }
              className="bg-orangeFoodiv text-white px-4 py-3 rounded-lg shadow-sm hover:shadow-orange-400 transition-all flex items-center"
            >
              <Plus className="" />
            </button>
          </div>
        </div>
      )}

      {selectedTab !== "todas" && (
        <div className="w-auto h-auto">
          {categories
            .filter((category) => category.name === selectedTab)
            .map((category) => (
              <div key={category.name}>
                {category.products.length > 0 ? (
                  <>
                    {category.products
                      .filter((product) =>
                        product.name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((product, index) => (
                        <div
                          className="w-full h-[80px] bg-white border rounded-3xl p-5 flex flex-row items-center justify-between mb-4 cursor-pointer shadow-sm hover:shadow-gray-400"
                          key={index}
                          onClick={() =>
                            handleEditProduct(product.categoryName, product)
                          }
                        >
                          <div className="flex-1">
                            <p className="text-gray-950 text-lg">
                              {capitalize(product.name)}
                            </p>
                            <p className="text-gray-600 text-md font-extralight">
                              {capitalize(product.description) ||
                                "Sin descripción"}
                            </p>
                          </div>
                          <div className="flex items-center space-x-2">
                            <p className="text-gray-800 text-md font-medium">
                              {formatToCLP(product.price)}
                            </p>
                            <ChevronRight className="text-gray-800" />
                          </div>
                        </div>
                      ))}
                    <div className="fixed bottom-10 right-10">
                      <button
                        onClick={() =>
                          setModalProducts({
                            open: true,
                            mode: "create",
                            categoryName: selectedTab,
                          })
                        }
                        className="bg-orangeFoodiv text-white px-4 py-3 rounded-lg shadow-sm hover:shadow-orange-400  transition-all flex items-center"
                      >
                        <Plus className="" />
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="w-auto h-[60vh] flex items-center justify-center ">
                    <div className="w-[60vw] h-auto bg-white border rounded-2xl p-8 text-center ">
                      <Box className="mx-auto mb-7 text-gray-600 w-16 h-16" />
                      <p className="text-gray-800 mb-6 text-lg">
                        Ningún producto registrado, crea tu primer producto.
                      </p>
                      <button
                        className="pl-6 pr-4 py-2 flex items-center w-full justify-center rounded-2xl border border-gray-300 bg-transparent text-gray-500 hover:bg-orange-100"
                        onClick={() => {
                          setModalProducts({
                            open: true,
                            mode: "create",
                            categoryName: selectedTab,
                          });
                        }}
                      >
                        <Box className="mr-2" />
                        Crear producto
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </>
  );

  const whithOutCategori = (
    <div className="w-auto h-[80vh] flex items-center justify-center ">
      <div className="w-[60vw] h-auto bg-white border rounded-2xl p-8 text-center ">
        <BookOpen className="mx-auto mb-4 text-gray-600 w-16 h-16" />
        <p className="text-gray-800 mb-6 text-lg">
          Ningúna categoría registrada, crea tu primera categoría.
        </p>
        <button
          className="pl-6 pr-4 py-2 flex items-center w-full justify-center rounded-2xl border border-gray-300 bg-transparent text-gray-500 hover:bg-orange-100"
          onClick={() => {
            setModalCategori({ open: true, mode: "create", categoryName: "" });
          }}
        >
          <Plus className="mr-2" />
          Agregar categoría
        </button>
      </div>
    </div>
  );
  // const filteredProducts = allProducts.filter((product) =>
  //   product.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );
  return (
    <div className="w-full h-screen flex flex-col">
      <div className=" w-full max-w-screen-xl mx-auto">
        <div className="flex items-center justify-between px-4 py-3 mb-2">
          <h1 className="text-orangeFoodiv text-3xl font-semibold">Catálogo</h1>
        </div>

        {categories.length === 0 ? whithOutCategori : whithContent}
        {modalCategori.open && (
          <div
            className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center"
            style={{ zIndex: 1050 }}
          >
            <div
              ref={modalCategoriRef}
              className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm flex flex-col text-center"
            >
              <h2 className="text-2xl mb-4 text-orangeFoodiv">
                {modalCategori.mode === "create"
                  ? "Agregar categoría"
                  : "Editar categoría"}
              </h2>

              <input
                placeholder="Ingresa categoría"
                value={capitalize(modalCategori.categoryName)}
                onChange={(e) =>
                  setModalCategori({
                    ...modalCategori,
                    categoryName: capitalize(e.target.value),
                  })
                }
                className="w-full p-4 bg-gray-100 rounded-2xl mb-4 focus:outline-none focus:ring-0"
              />
              <div className="flex justify-end">
                <button
                  onClick={() =>
                    setModalCategori({
                      open: false,
                      mode: "create",
                      categoryName: "",
                    })
                  }
                  className="bg-gray-200 text-gray-700 px-4 py-2 rounded-2xl hover:bg-gray-300 mr-2"
                >
                  Cancelar
                </button>
                <button
                  onClick={handleModalSubmit}
                  className="bg-orangeFoodiv text-white px-4 py-2 rounded-2xl hover:bg-orange-500"
                >
                  {modalCategori.mode === "create" ? "Crear" : "Guardar"}
                </button>
              </div>
            </div>
          </div>
        )}
        {modalProducts.open && (
          <div
            className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center"
            style={{ zIndex: modalCategori.open ? 1000 : 1050 }}
          >
            <div
              ref={modalProductsRef}
              className="bg-white rounded-lg shadow-lg p-6 w-full max-w-2xl flex flex-col text-center"
            >
              <h2 className="text-2xl mb-10 text-orangeFoodiv">
                {modalProducts.mode === "create"
                  ? "Agregar producto"
                  : "Editar producto"}
              </h2>

              <div className="relative bg-gray-100 rounded-2xl mb-6">
                <LabelFull
                  className="w-[25px] h-[25px] absolute left-2.5 text-gray-500"
                  style={{ top: "50%", transform: "translateY(-50%)" }}
                />
                <input
                  placeholder="Nombre del producto"
                  value={modalProducts.producName}
                  onChange={(e) =>
                    setModalProducts({
                      ...modalProducts,
                      producName: capitalize(e.target.value),
                    })
                  }
                  className="w-full p-4 pl-11 bg-transparent rounded-2xl focus:outline-none focus:ring-0"
                />
              </div>

              <div className="relative bg-gray-100 rounded-2xl mb-6">
                <DollarSign className="w-[25px] h-[25px] absolute left-2.5 top-3.5 z-10 text-gray-500" />
                <input
                  placeholder="Valor"
                  type="number"
                  value={modalProducts.price}
                  onChange={(e) =>
                    setModalProducts({
                      ...modalProducts,
                      price: e.target.value,
                    })
                  }
                  className="w-full p-4 pl-11 bg-transparent rounded-2xl focus:outline-none focus:ring-0"
                />
              </div>

              <div className="relative bg-gray-100 rounded-2xl mb-6">
                <AlignLeft className="w-[25px] h-[25px] absolute left-2.5 top-3.5 z-10 text-gray-500" />
                <textarea
                  placeholder="Descripción"
                  value={modalProducts.description}
                  onChange={(e) =>
                    setModalProducts({
                      ...modalProducts,
                      description: capitalize(e.target.value),
                    })
                  }
                  className="w-full p-4 pl-11 bg-transparent rounded-2xl focus:outline-none focus:ring-0"
                />
              </div>

              <div
                className="w-full h-full flex items-center mb-4"
                id="ayudaAqui"
              >
                <div className="relative bg-gray-100 rounded-2xl mb-4 flex items-center px-4 w-full">
                  <Package className="w-[25px] h-[25px]  absolute left-2.5 top-3.5 z-10 text-gray-500 " />
                  <select
                    value={modalProducts.categoryName}
                    onChange={(e) =>
                      setModalProducts({
                        ...modalProducts,
                        categoryName: capitalize(e.target.value),
                      })
                    }
                    className="w-full p-4 pl-6 bg-transparent rounded-2xl focus:outline-none focus:ring-0"
                  >
                    <option value="" disabled>
                      Selecciona una categoría
                    </option>
                    {categories.map((category, i) => (
                      <option key={i} value={category.name}>
                        {capitalize(category.name)}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-4 ml-2">
                  <button
                    className="bg-white hover:bg-gray-200  w-full h-full rounded-lg p-3 border"
                    onClick={(e) =>
                      setModalCategori({
                        open: true,
                        mode: "create",
                        categoryName: e.target.value,
                      })
                    }
                  >
                    <Plus className="text-gray-500" />
                  </button>
                </div>
              </div>
              <div className="flex items-center space-x-4 mb-8">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="product-type"
                    className="checkbox-round w-5 h-5"
                    checked={isDrink}
                    onChange={handleDrinkChange}
                  />
                  <span className="ml-2">Es bebestible</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="product-type"
                    className="checkbox-round w-5 h-5"
                    checked={!isDrink}
                    onChange={handleEdibleChange}
                  />
                  <span className="ml-2">Es comestible</span>
                </label>
              </div>

              <div className="flex justify-between">
                {modalProducts.mode === "edit" && (
                  <div>
                    <button
                      className="flex items-center text-orangeFoodiv p-2 rounded-2xl w-auto hover:bg-orange-200 border border-orange-400"
                      onClick={() => {
                        handleDeleteProdut();
                      }}
                    >
                      <TrashIcon />
                      Eliminar producto
                    </button>
                  </div>
                )}

                <div
                  className={
                    modalProducts.mode !== "edit" &&
                    "w-full flex flex-row justify-end"
                  }
                >
                  <button
                    onClick={() =>
                      setModalProducts({
                        open: false,
                        mode: "create",
                        categoryName: "",
                        producName: "",
                        price: "",
                        description: "",
                      })
                    }
                    className="bg-gray-200 text-gray-700 px-4 py-2 rounded-2xl hover:bg-gray-300 mr-2"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={handleModalProductSubmit}
                    className={`bg-orangeFoodiv text-white px-4 py-2 rounded-2xl ${
                      !modalProducts.producName || !modalProducts.price
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-orange-400"
                    }`}
                    disabled={!modalProducts.producName || !modalProducts.price}
                  >
                    {modalProducts.mode === "create" ? "Crear" : "Guardar"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Catalogo;
