import {
  arrayUnion,
  doc,
  updateDoc,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../database/config";

export const addTableToRestaurant = async (restaurantId, newTable) => {
  try {
    const restaurantDocRef = doc(db, "restaurants", restaurantId);

    await updateDoc(restaurantDocRef, {
      tables: arrayUnion(newTable),
    });

    console.log("Mesa añadida correctamente");
  } catch (error) {
    console.error("Error al añadir la mesa: ", error);
  }
};

export const getExistingTables = async (restaurantId) => {
  console.log("object");
  try {
    const restaurantDocRef = doc(db, "restaurants", restaurantId);
    console.log("object2", restaurantDocRef);

    const docSnapshot = await getDoc(restaurantDocRef);
    console.log("object3", docSnapshot.data());

    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      return data.tables || [];
    } else {
      console.log("El documento no existe.");
      return [];
    }
  } catch (error) {
    console.error("Error al obtener a los meseros existentes: ", error);
    return [];
  }
};
export const subscribeToExistingTables = (restaurantId, callback) => {
  try {
    const restaurantDocRef = doc(db, "restaurants", restaurantId);

    // Usamos onSnapshot para escuchar los cambios en tiempo real
    const unsubscribe = onSnapshot(restaurantDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        console.log("Datos de las mesas actualizados:", data.tables); // Agregar log para verificar los datos
        callback(data.tables || []);
      } else {
        console.log("El documento no existe.");
        callback([]);
      }
    });

    // Retorna la función para desuscribirse
    return unsubscribe;
  } catch (error) {
    console.error("Error al suscribirse a las mesas existentes: ", error);
    return () => {};
  }
};

export const updateTables = async (restaurantId, updatedTables) => {
  try {
    const restaurantDocRef = doc(db, "restaurants", restaurantId);

    await updateDoc(restaurantDocRef, {
      tables: updatedTables,
    });

    console.log("Mesas actualizadas correctamente.");
  } catch (error) {
    console.error("Error al actualizar las mesas: ", error);
  }
};
