import "./App.css";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Pages
import MainLayout from "./pages/main_layout";
// import Home from "./pages/home";
import Login from "./pages/login";
import Tables from "./pages/tables";
import Catalogo from "./pages/catalogo";
import Reports from "./pages/reports";
import Waiters from "./pages/waiters";
import Settings from "./pages/settings";

function App() {
  const logged = useSelector((state) => state.auth.logged);
  const userId = useSelector((state) => state.auth.idUser);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />

        <Route element={<MainLayout title="App Layout" />}>
          <Route path="/tables" element={<Tables />} />
          <Route path="/catalogo" element={<Catalogo />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/waiters" element={<Waiters />} />
          <Route path="/settings" element={<Settings />} />
        </Route>

        {/* Página 404 */}
        <Route
          path="*"
          element={
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-4xl font-bold text-blue-500">
                No hay nada aquí...
              </h1>
              <img src={""} alt="not-found" className="w-1/2" />
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
