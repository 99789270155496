import {
  arrayUnion,
  doc,
  updateDoc,
  getDoc,
  getDocs,
  query,
  collection,
  where,
  deleteDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../database/config";

export const getCatalogsByRestaurantId = async (restaurantId) => {
  try {
    const catalogsCollectionRef = collection(db, "catalogs");
    const q = query(
      catalogsCollectionRef,
      where("restaurantId", "==", restaurantId),
      where("status", "==", "active")
    );

    const querySnapshot = await getDocs(q);
    const catalogs = [];

    querySnapshot.forEach((doc) => {
      catalogs.push({ id: doc.id, ...doc.data() });
    });

    return catalogs;
  } catch (error) {
    console.error("Error al obtener las categorías: ", error);
    return [];
  }
};
export const createNewCatalog = async (restaurantId, categories) => {
  try {
    const validCategories = categories || [];
    const docRef = await addDoc(collection(db, "catalogs"), {
      restaurantId,
      status: "active",
      categories: validCategories,
      createdAt: new Date(),
    });
    return docRef.id;
  } catch (error) {
    console.error("Error al crear un nuevo catálogo: ", error);
    throw new Error("No se pudo crear el catálogo.");
  }
};

export const updateCatalog = async (catalogId, updatedData) => {
  try {
    const catalogDocRef = doc(db, "catalogs", catalogId);

    // Obtén el documento actual para evitar sobrescribir datos
    const existingDoc = await getDoc(catalogDocRef);
    if (!existingDoc.exists()) {
      throw new Error("El catálogo no existe.");
    }

    // Fusiona los datos existentes con los nuevos
    const existingData = existingDoc.data();
    const mergedData = {
      ...existingData,
      ...updatedData,
      updatedAt: new Date(),
    };

    await updateDoc(catalogDocRef, mergedData);
    return { success: true, message: "Documento actualizado correctamente." };
  } catch (error) {
    console.error("Error al actualizar el documento: ", error);
    return { success: false, message: "Error al actualizar el documento." };
  }
};

export const deleteProduct = async (productNameToDelete, restaurantId) => {
  if (!productNameToDelete) {
    return false;
  }

  try {
    // Obtenemos el catálogo actual del restaurante desde Firebase
    const catalogsCollectionRef = collection(db, "catalogs");
    const q = query(
      catalogsCollectionRef,
      where("restaurantId", "==", restaurantId),
      where("status", "==", "active")
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return false;
    }

    const catalogDoc = querySnapshot.docs[0]; // Asumimos que hay un catálogo activo por restaurante
    const catalogData = { id: catalogDoc.id, ...catalogDoc.data() };

    if (!catalogData.categories) {
      return false;
    }

    // Actualizamos las categorías eliminando el producto especificado
    const updatedCategories = catalogData.categories.map((category) => {
      return {
        ...category,
        products: category.products.filter(
          (product) => product.name !== productNameToDelete
        ),
      };
    });

    // Guardamos las categorías actualizadas en Firebase
    const catalogDocRef = doc(db, "catalogs", catalogData.id);
    await updateDoc(catalogDocRef, { categories: updatedCategories });
  } catch (error) {
    console.error("Error al eliminar el producto:", error);
  }
};
