import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import { db } from "../database/config";
// import { format } from "date-fns";

export const getDailyReport = async (day, restaurantId) => {
  try {
    console.log("day", day);

    // Convertir el string de fecha a un objeto Date
    const dateObj = new Date(day);
    if (isNaN(dateObj)) {
      throw new Error("Formato de fecha no válido");
    }

    // Obtener las fechas de inicio y fin del día
    const startDate = new Date(dateObj);
    startDate.setHours(0, 0, 0, 0); // Inicio del día
    const endDate = new Date(dateObj);
    endDate.setHours(23, 59, 59, 999); // Fin del día

    // Obtener las fechas del día anterior
    const prevStartDate = new Date(startDate);
    prevStartDate.setDate(prevStartDate.getDate() - 1);
    const prevEndDate = new Date(endDate);
    prevEndDate.setDate(prevEndDate.getDate() - 1);

    const paysRef = collection(db, "pays");

    // Consultas para el día actual y el día anterior
    const currentDayQuery = query(
      paysRef,
      where("restaurantId", "==", restaurantId),
      where("payDate", ">=", startDate),
      where("payDate", "<=", endDate),
      where("status", "==", "accepted")
    );
    const prevDayQuery = query(
      paysRef,
      where("restaurantId", "==", restaurantId),
      where("payDate", ">=", prevStartDate),
      where("payDate", "<=", prevEndDate),
      where("status", "==", "accepted")
    );

    const [currentDaySnapshot, prevDaySnapshot] = await Promise.all([
      getDocs(currentDayQuery),
      getDocs(prevDayQuery),
    ]);

    // Función para calcular totales
    const calculateTotals = (snapshot) => {
      let totals = {
        total: 0,
        tips: 0,
        cashPays: 0,
        cardPays: 0,
        paysCash: 0,
        paysDebit: 0,
        paysCredit: 0,
      };

      snapshot.forEach((doc) => {
        const data = doc.data();
        totals.total += parseInt(data.amountTotalPay.replace(/\D/g, ""), 10);

        (data.infoPays || []).forEach((pay) => {
          const amount = parseInt(pay.amount.replace(/\D/g, ""), 10);
          if (pay.typePay === "Efectivo") {
            totals.cashPays++;
            totals.paysCash += amount;
          } else if (pay.typePay.toLowerCase() === "tarjeta de débito") {
            totals.cardPays++;
            totals.paysDebit += amount;
          } else if (pay.typePay.toLowerCase() === "tarjeta de crédito") {
            totals.cardPays++;
            totals.paysCredit += amount;
          }

          if (pay.tip && pay.tip !== "Sin propina") {
            totals.tips += parseInt(pay.tip.replace(/\D/g, ""), 10);
          }
        });
      });

      return totals;
    };

    // Calcular los totales para el día actual y el día anterior
    const dataToday = calculateTotals(currentDaySnapshot);
    const dataBack = calculateTotals(prevDaySnapshot);

    return { dataToday, dataBack };
  } catch (error) {
    console.error("Error al obtener el reporte diario:", error);
    return false;
  }
};
