import DatePicker from "react-datepicker";
import ResponsiveChart from "../components/chart_component";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { getDailyReport } from "../database/reports";
import { useSelector } from "react-redux";
import { ReactComponent as Minus } from "../assets/icons/minus.svg";
import { ReactComponent as MinusCircle } from "../assets/icons/minus-circle.svg";
import { ReactComponent as PlusCircle } from "../assets/icons/plus-circle.svg";
import { formatToCLP } from "../utils/functions";
import es from "date-fns/locale/es";
const ComparisonCard = ({ title, valueToday, valueBack, comparisonType }) => {
  const comparisonResult = {
    Ganancia: (
      <p className="text-green-600 flex items-center">
        <PlusCircle className="w-5 h-5 mr-1" />
        Ganancias
      </p>
    ),
    Pérdida: (
      <p className="text-red-600 flex items-center">
        <MinusCircle className="w-5 h-5 mr-1" />
        Pérdida
      </p>
    ),
    "Sin cambios": (
      <p className="text-orangeFoodiv flex items-center">
        <Minus className="w-5 h-5 mr-1" />
        Sin cambios
      </p>
    ),
  };

  const colorMap = {
    Ganancia: "text-green-600",
    Pérdida: "text-red-600",
    "Sin cambios": "text-orangeFoodiv",
  };

  const currentComparison = comparisonType(valueToday, valueBack);
  console.log("valueToday", valueToday);
  return (
    <div className="flex justify-between items-center p-4 bg-white border border-gray-300 rounded-xl mb-4">
      <div>
        <h5 className="text-gray-900">{title}</h5>
        {comparisonResult[currentComparison]}
      </div>
      <h5 className={`text-lg font-bold ${colorMap[currentComparison]}`}>
        {formatToCLP(valueToday)}
      </h5>
    </div>
  );
};

export default function Reports() {
  const restaurantId = useSelector((state) => state.auth?.userData.id) || "";

  const [dataBack, setDataBack] = useState({
    total: 0,
    cardPays: 0,
    cashPays: 0,
    tips: 0,
  });

  const [dataToday, setDataToday] = useState({
    total: 0,
    cardPays: 0,
    cashPays: 0,
    tips: 0,
  });

  const [startDate, setStartDate] = useState(new Date());
  const [dataPoints, setDataPoints] = useState([500, 12450, 3500, 5000, 10000]);

  useEffect(() => {
    const fetchData = async () => {
      const report = await getDailyReport(startDate, restaurantId);
      if (report) {
        setDataBack(
          report.dataBack || { total: 0, cardPays: 0, cashPays: 0, tips: 0 }
        );
        setDataToday(
          report.dataToday || { total: 0, cardPays: 0, cashPays: 0, tips: 0 }
        );
      }
    };
    fetchData();
  }, [startDate, restaurantId]);

  const handleDatePicker = async (date) => {
    setStartDate(date);
    const report = await getDailyReport(date, restaurantId);
    if (report) {
      setDataBack(
        report.dataBack || { total: 0, cardPays: 0, cashPays: 0, tips: 0 }
      );
      setDataToday(
        report.dataToday || { total: 0, cardPays: 0, cashPays: 0, tips: 0 }
      );
    }
  };

  const totalMonto = dataToday.total + dataToday.tips;
  const calculatePercentage = (value) =>
    totalMonto > 0 ? ((value / totalMonto) * 100).toFixed(0) : 0;
  console.log("dataToday", dataToday);
  const cardPercentage = calculatePercentage(dataToday.cardPays);
  const cashPercentage = calculatePercentage(dataToday.paysCash);
  const tipsPercentage = calculatePercentage(dataToday.tips);

  const compare = (today, back) => {
    if (today > back) return "Ganancia";
    if (today < back) return "Pérdida";
    return "Sin cambios";
  };
  const today = new Date(); // Fecha de hoy
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2); // Hace 2 meses

  return (
    <div className="w-full">
      <div className=" w-full max-w-screen-xl mx-auto">
        <div className="flex items-center justify-between px-4 py-2 mb-2">
          <h1 className="text-orangeFoodiv text-3xl font-semibold">Reportes</h1>
        </div>
        <div className="w-full flex flex-row mt-8">
          <div className="w-full p-4">
            <ResponsiveChart dataPoints={dataPoints} />
          </div>
          <div className="w-full h-full flex flex-col p-4">
            <DatePicker
              locale={es}
              selected={startDate}
              onChange={handleDatePicker}
              dateFormat="dd-MM-yyyy"
              className="p-3 border border-orange-300 rounded-xl w-full mb-4 focus:outline-none focus:ring focus:ring-orange-200"
              placeholderText="Seleccionar fecha"
              popperPlacement="bottom"
              maxDate={today}
              minDate={twoMonthsAgo}
            />
            <div className="h-full w-full border border-orange-300 bg-white rounded-2xl flex flex-col p-6">
              <h5 className="text-center text-lg font-semibold text-gray-700 mb-4">
                Total
              </h5>
              <h3 className="text-center text-4xl font-extrabold text-orangeFoodiv mb-6">
                {formatToCLP(dataToday.total + dataToday.tips)}
              </h3>
              <div className="w-full flex flex-row justify-between">
                <div className="w-1/3 flex flex-col justify-center items-center">
                  <h5 className="text-2xl font-semibold text-orangeFoodiv">
                    {cardPercentage}%
                  </h5>
                  <h5 className="text-sm text-gray-500">Tarjetas</h5>
                </div>
                <div className="w-1/3 flex flex-col justify-center items-center">
                  <h5 className="text-2xl font-semibold text-orangeFoodiv">
                    {cashPercentage}%
                  </h5>
                  <h5 className="text-sm text-gray-500">Efectivo</h5>
                </div>
                <div className="w-1/3 flex flex-col justify-center items-center">
                  <h5 className="text-2xl font-semibold text-orangeFoodiv">
                    {tipsPercentage}%
                  </h5>
                  <h5 className="text-sm text-gray-500">Propinas</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full p-4">
          <ComparisonCard
            title="Efectivo"
            valueToday={dataToday.paysCash}
            valueBack={dataBack.paysCash}
            comparisonType={compare}
          />
          <ComparisonCard
            title="Tarjetas"
            valueToday={dataToday.paysCredit + dataToday.paysDebit}
            valueBack={dataBack.paysCredit + dataBack.paysDebit}
            comparisonType={compare}
          />
          <ComparisonCard
            title="Propinas"
            valueToday={dataToday.tips}
            valueBack={dataBack.tips}
            comparisonType={compare}
          />
        </div>
      </div>
    </div>
  );
}
