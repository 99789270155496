import { collection, query, where, getDocs, limit } from "firebase/firestore";
import { db } from "./config"; // Importa la configuración de Firestore

export const handleLoginUser = async (restaurantCode, restaurantPin) => {
  try {
    // Crea la consulta
    console.log("datos", restaurantCode, restaurantPin);
    const q = query(
      collection(db, "restaurants"),
      where("code", "==", restaurantCode),
      where("pin", "==", restaurantPin),
      where("status", "==", "active"),
      limit(1)
    );
    console.log("query", q);
    // Ejecuta la consulta
    const querySnapshot = await getDocs(q);

    // Verifica los resultados
    if (querySnapshot.empty) {
      return false; // Login fallido
    } else {
      // Retorna los datos del primer documento
      return querySnapshot.docs[0].data();
    }
  } catch (error) {
    console.error("Error during login:", error);
    return null; // Manejo de errores
  }
};
